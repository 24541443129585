import { Component, OnInit } from '@angular/core';
import { personnelData } from './media-list'

@Component({
  selector: 'app-personnel',
  templateUrl: './personnel.component.html',
})
export class PersonnelComponent implements OnInit {

  personnelData
  mobileDevice:boolean = false

  constructor() { }

  ngOnInit() {
    this.personnelData = personnelData
    if (window.screen.width < 501) {
      this.mobileDevice = true;
    }
  }

}
