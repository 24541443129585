export const personnelData = [
  {
    name: 'Nicole Godfrey',
    title: 'Owner',
    location: 'North America',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Nicole.mp4',
    heroPath: './assets/images/nicole.png'
  },
  {
    name: 'Rowan Kelly ',
    title: 'Owner',
    location: 'North America',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Rowan.mp4',
    heroPath: './assets/images/rowan.png'
  },
  {
    name: 'Paul Le Fevre',
    title: 'Product Manager',
    location: 'Melbourne, Australia',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Paul.mov',
    heroPath: './assets/images/paul.png'
  },
  {
    name: 'Pradeepkumar Muniraju',
    title: 'Senior Developer',
    location: 'Melbourne, Australia',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Pradeep.mov',
    heroPath: './assets/images/pradeep.png'
  },
  {
    name: 'Sally Armstrong',
    title: 'Global Software Implementation Director',
    location: 'Melbourne, Australia',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Sally.mp4',
    heroPath: './assets/images/sally.png'
  },
  {
    name: 'Keerthi Muniswamy',
    title: 'Project Manager',
    location: 'Bangalore, India',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Keerthi.mp4',
    heroPath: './assets/images/keerthi.png'
  },
  {
    name: 'Chudamani Eshwarachari',
    title: 'Team Leader',
    location: 'Bangalore, India',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Chudamani.mp4',
    heroPath: './assets/images/chudamani.png'
  },
  {
    name: 'Ethan Townsend',
    title: 'Front-End Developer ',
    location: 'North America',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Ethan.mp4',
    heroPath: './assets/images/ethan.png'
  },
  {
    name: 'Sailesh Kc',
    title: 'UI/UX Designer/Front-End Developer ',
    location: 'North America',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Sailesh.mp4',
    heroPath: './assets/images/sailesh.png'
  },
  {
    name: 'Jannie Phan',
    title: 'UI/UX Designer',
    location: 'North America',
    videoPath: 'https://public-runway-assets.s3.us-east-2.amazonaws.com/sandbrock-videos/Jannie.mov',
    heroPath: './assets/images/jannie.png'
  },
];
