import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PersonnelComponent } from './personnel/personnel.component';


const routes: Routes = [
  {path: '', redirectTo: 'sandbrockPersonnel', pathMatch: 'full'},
  {
    path: 'sandbrockPersonnel',
    component: PersonnelComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
